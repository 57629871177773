@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;700&display=swap');

.App {
  text-align: center;
}

.navbar-collapse-custom {
  position: relative; /* Default positioning for larger screens */
} 

@media (max-width: 992px) {
.navbar-collapse-custom {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: black; /* Set the background color as needed */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Add a box shadow for styling */
  z-index: 1000; /* Set a higher z-index to make sure it appears above other content */
}
}


.logo {
  height: 90px;
  width: 100%;
}

.logoTwo {
  height: 40px;
  width: 40px;
}

:root {
  --C_primary: #2e750d;
  --C_secondary: #2B6112;
  --C_primary_hover: #4e942e;
  --C_primary_disabled: #0f2904;
  --C_gray_lite_two: #CCD8E1;
  --C_success: #a2b7c7;
  --C_success_two: #6687a0;
  --C_danger: #E25353;
  --C_border_danger: #FF2414;
  --C_warning: #FFD56A;
  --C_bg_danger: #FFEFEB;
  --C_white: #FFFFFF;
  --C_black: #0F1011;
  --C_black_lite: #1D2E3D;
  --c_gray: #101924;
  --c_gray_lite: #dbd4d4;
  --C_blue_light: #091e42;
  --BG_primary: #F9F9F9;
  --F_mont: 'Inter', sans-serif;
  --F_24: 24px;
  --c_success_hover: #D6F6C5;
  --c_success_disabled: #556777;
}

#root {
  height:  100vh;
  width: 100vw;
}
.App {
  margin: 0;
  font-family: var(--F_mont);
  /* height:  100vh; */
}
body{
  overflow-x: hidden !important;
  background: var(--BG_primary);
}

body::-webkit-scrollbar,
.App::-webkit-scrollbar,
.scroll::-webkit-scrollbar {
 width: 7px;
 height: 20px;
}
body::-webkit-scrollbar-thumb,
.App::-webkit-scrollbar-thumb,
.scroll::-webkit-scrollbar-thumb 
 {
 background: linear-gradient(to top, #333, var(--c_gray));
}
body::-webkit-scrollbar-track,
.App::-webkit-scrollbar-track,
.scroll::-webkit-scrollbar-track {
 /* background: #bbb; */
 width: 8px;
}

.error__msg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  font-family: var(--F_mont);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: var(--F_24);
  background-color: var(--C_bg_danger);
  color: var(--C_danger);
}

.passwordError {
  background: #F8FAFB;
}

.passwordError p {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--C_black_lite);
  font-family: var(--F_mont);
}

.border-danger {
  border: 1px solid var(--C_border_danger);
}

#F04E45

input, select {
  outline: none !important;
}

a li:hover {
  color: #2B6112;
}
.text-active{
  color: #2B6112 !important;
}
select:hover, option:hover {
  cursor: pointer;
} 
  
  .text-feint {
    color: #9E9E9E;
  }
  .drag_active {
    background: rgba(0, 0, 0, 0.8);
    color: var(--C_white) !important;
  }

  img[class*="cursor-pointer"]{
    padding: 7px;
    margin-top: 0 !important;
    border-radius: 50px;
    transition: background-color 0.5s ease;
  }
  img[class*="cursor-pointer"]:hover{
    background-color: var(--C_gray_lite);
    transition: background-color 0.5s ease;
  }

  
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.nav-pills .nav-link:hover{
  border-radius: 0;
  color: #cca354;
  background-color: #f4f0f0;
  border-bottom: 2px solid #cca354;
}

.nav-pills .nav-link{
  color: #cca354;
  background-color: #f4f0f0;
  border-bottom: 2px solid #cca354;
}

.nav-pills .nav-link.active{
  color: #f4f0f0;
  background-color: #cca354 !important;
  border-bottom: 2px solid #f4f0f0;
}

.nav-pills .nav-item:not(:nth-last-of-type(1)) .nav-link{
  /* border-right: 2px solid #ffff; */
  margin-right: 3px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cdStyles {
  background-image: url("../public/images/card-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  border: 3px solid #cca354 !important;
  border-radius: 10px;
  width: 100% !important;
  height: 400px !important;
  box-shadow: 0px 0px 15px #cca354;
  color: #fff;
  font-size: calc(10px + 1.3vmin);
}

.crd {
  height: 100%;
  margin: 0 auto !important;
  padding-bottom: 25px !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* background-color: #282c34 !important; */
  color: #6b4130;
  font-size: calc(8px + 1vmin);
}

.crd h4, a {
  color: #cca354;
  text-decoration: none;
}

._cryp {
  color: #626b7f;
  line-height: 14px;
  font-weight: 400;
  font-size: 11px;
  box-sizing: border-box;
  padding: 2px 6px;
  width: 100%;
  font-family: Verdana, Tahoma, Arial, sans-serif;
}

.cryp {
  border: 0;
  margin: 0;
  padding: 0;
}

.cryp_1 {
  height: 669px;
  background-color: #1d2330;
  overflow: hidden;
  box-sizing: border-box;
  border: 1px solid #282e3b;
  border-radius: 4px;
  text-align: right;
  line-height: 14px;
  font-size: 12px;
  font-feature-settings: normal;
  /* text-size-adjust: 100%; */
  box-shadow: inset 0 -20px 0 0 #262b38;
  padding: 0px;
  margin: 0px;
  width: 100%;
}

.cryp_2 {
  height: 649px;
  padding: 0px;
  margin: 0px;
  width: 100%;
}

.cryp_3 {
  font-weight: 500;
  color: #626b7f;
  text-decoration: none;
  font-size: 11px;
}

.wcv {
  width: 31% !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tble {
  margin: 0 auto !important;
  width: 80% !important;
}

.t-head {
  border-top: 2px solid #cca354 !important;
  border-bottom: 4px solid #cca354 !important;
}

.t-body {
  border: 2px solid #cca354 !important;
}

.tble_1 {
  margin: 0 auto !important;

  width: 70% !important;
}

.rights {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.img-footTwo {
  width: 75%;
  height: 100%;
}

.img-foot {
  width: 80%;
  height: 100%;
  padding-bottom: 50px;
}

/* .border-danger {
  color: rgb(6, 4, 109) !important;
  height: 0.3em !important;
} */

.bgBlack {
  border-top: 4px solid #cca354 !important;
  background-color: #131010 !important;
}

.backgrnd-0 {
  border-top: 4px solid #cca354 !important;
  background-image: url("../public/images/DarkBackground_2.webp") !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.simMap {
  margin-top: 30px !important;
}

.sub-bg {
  background-image: url("../public/images/stars.gif") !important;
  background-size: repeat !important;
  /* background-repeat: no-repeat !important; */
  height: 80vh !important;
  padding-top: 10% !important;
}

.Ai-bg {
  background-color: #cca354 !important;
}

.backgrnd1 {
  background-image: url("../public/images/DarkBackground_1.jpeg") !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.bg {
  border-top: 4px solid #cca354 !important;
}

.news-img {
  width: 93% !important;
  margin: 13px auto !important;
}

.news-bg {
  background-image: url("./images/4479-V17054557.jpg") !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  border-top: 4px solid #cca354 !important;
}

.payment-method-img {
  border: 4px solid #cca354 !important;
}
.payment-method img {
  /* width: 60% !important; */
  height: auto !important;
  margin-top: 20px;
  margin-bottom: 20px;
}

.region-rep {
  width: 70% !important;
  height: 100% !important;
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.tble_1 {
  border: 2px solid #cca354 !important;
  box-shadow: 0px 0px 10px #cca354;
}

.tble_2 {
  border: 2px solid #cca354 !important;
  background-color: transparent !important;
}
@media (min-width: 991px) {
  .team-member {
    width: 22% !important;
  }
}
.team-member {
  margin: 0 auto !important;

  height: 100% !important;
  margin-top: 80px !important;
  margin-bottom: 50px !important;
}

.team-img img {
  padding-top: 20px !important;
  width: 95% !important;
  height: 60% !important;
}

.team-bg {
  border-top: 4px solid #cca354 !important;
  background-image: url("../public/images/DarkBackground_6.jpg") !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.feedback {
  /* width: 30% !important; */
  height: 100% !important;
  margin: 0 auto !important;
  background-color: rgb(211, 206, 206);
  border-radius: 20px;
}

.feedback-img {
  width: 18% !important;
  height: 18% !important;
  border-radius: 50% !important;
  border: 3px solid #cca354 !important;
  margin-right: 20px !important;
}

.feedback-bg {
  background-image: url("../public/images/DarkBackground_4.jpg") !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.feedback-bg-dash {
  background-image: url("../public/images/DarkBackground_4.jpg") !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  /* width: 100% !important; */
  height: 100% !important;
}

.feedback-bg-dash-2 {
  background-image: url("../public/images/DarkBackground_4.jpg") !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  /* width: 100% !important; */
  height: 100% !important; 
}

.feedback-bg-dash-3 {
  background-image: url("../public/images/DarkBackground_4.jpg") !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  /* width: 100% !important;
  height: 100% !important; */
}

.feedback-bg-dash-4 {
  background-image: url("../public/images/DarkBackground_4.jpg") !important;
  background-size: cover !important;
  background-repeat: repeat !important;
  /* width: 100% !important; */
  /* height: 100% !important; */
}

.faq-1 {
  width: 60% !important;
  margin: 0 auto !important;
  background-color: #343a40 !important;
  color: #fff !important;
}


.faq-bg {
  background-image: url("../public/images/faqbg-img.jpg") !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.hIworks {
  width: 100px !important;
  height: 100px !important;
  margin: 0 auto !important;
  border-radius: 50% !important;
  border: 3px solid #cca354 !important;
  box-shadow: 0px 0px 20px #cca354 !important;
  /* background-color: #444444 !important; */
  position: relative !important;
}

.hIworks img {
  margin-top: 26px !important;
  width: 40% !important;
  height: 40% !important;
}

.hIworks-id {
  margin-top: 17% !important;
  font-size: 20px !important;
  font-weight: bold !important;
  position: absolute !important;
  top: -30px !important;
  left: 60px !important;
  border-radius: 50% !important;
  border: 2px solid #cca354 !important;
  color: #cca354 !important;
  padding-bottom: 10px !important;
  width: 35px !important;
  height: 35px !important;
  /* background-color: #444444 !important; */
}

.line {
  border-bottom: 3px dashed #6b4130 !important;
}

.wcv {
  color: #cca354 !important;
}

.new-bd {
  border: 4px solid #cca354 !important;
}

.btn-custom {
  background-color: #fff !important;
  border-color: #cca354 !important;
  color: #cca354 !important;
}

.btn-custom:hover {
  background-color: #cca354 !important;
  border-color: #cca354 !important;
  color: #fff !important;
}

.title-spn {
  color: #cca354 !important;
}

.choice-bg {
  background-image: url("../public/images/bitcoin-btc-cryptocurrency-coins-gold-money-coin-with-black-background_35652-2162.jpg") !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.invest-bg {
  background-image: url("../public/images/stars.gif") !important;
  background-size: repeat !important;
  opacity: 1 !important;
  /* background-repeat: no-repeat !important; */
}
@media (min-width: 800px) {
  .note-custom {
    width: 35% !important;
  }
  .refere-custom {
    width: 45% !important;
  }
}
.note-custom {
  background-color: rgb(10, 10, 10) !important;
  /* opacity: 0.9 !important; */
  width: 80%;
  height: 30% !important;
}

.refere-custom {
  background-color: rgb(10, 10, 10) !important;
  /* opacity: 1 !important; */
  margin-top: 40% !important;
  width: 80%;
  height: 40% !important;
}

.note-1 {
  color: #cca354 !important;
}

.signup-head {
  background-image: url("../public/images/sighnUpHead.jpg") !important;
  opacity: 0.8 !important;
  border-bottom: 2px solid #cca354 !important;
}

.invest-cap {
  font-size: 0.6em !important;
  height: 10% !important;

  background-color: #09d475 !important;
  color: #fff !important;
}

.about-bg {
  background-image: url("../public/images/cryp8.jpg") !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.hero-bg {
  background-image: url("../public/images/teslaHero.jpg") !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  height: 900px !important;
}

.pay-bg {
  background-image: url("../public/images/cryp-backgrnd.webp") !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  /* height: 900px !important; */
}

.desc {
  background-image: url(../public/images/stars.gif);
  background-size: repeat;
  height: 700px;
  /* background-repeat: no-repeat !important; */
}

.sign-up1 {
  margin: 0 auto !important;
  width: 40% !important;
}
@media (min-width: 500px) {
  .signUp-border {
    width: 45% !important;
  }
  .signUp-border2 {
    width: 45% !important;
  }
}
.signUp-border {
  border: #cca354 !important;
  margin: 0 auto !important;
  /* margin-top: 15% !important; */
  padding: 25px !important;

  height: 100% !important;
  border-radius: 0 0 10px 10px !important;
  background-color: black !important;
  opacity: 0.8 !important;
  box-shadow: 0 0 10px 2px #cca354 !important;
}

.signUp-border2 {
  border: #cca354 !important;
  border-bottom: 0px !important;
  margin: 0 auto !important;
  margin-top: 15% !important;
  padding: 25px !important;

  height: 100% !important;
  border-radius: 10px 10px 0 0 !important;
  box-shadow: 0 0 10px 2px #cca354 !important;
}

.form-con {
  border: 1px solid #cca354 !important;
  background-color: transparent !important;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  /* padding: 8px 0px 8px 16px; */
  list-style: none;
  /* height: 60px; */
}

.nav-text a {
  text-decoration: none;
  color: #3e497f;
  font-size: 17px;
  width: 100%;
  /* height: 50%; */
  display: flex;
  align-items: center;
  border-radius: 4px;
}

.nav-text a:hover {
  /* background-color: rgb(7, 87, 119); */
  color: #7f8cfd;
}

.nav-text2 {
  display: flex;
  justify-content: start;
  align-items: center;
  height: 0%;
  /* padding: 8px 0px 8px 16px; */
  list-style: none;
  /* height: 60px; */
}

.nav-text2 a {
  text-decoration: none;
  color: #3e497f;
  font-size: 20px;
  width: 100%;
  /* height: 50% !important; */
  display: flex;
  align-items: center;
  border-radius: 4px;
}

.nav-text2 a:hover {
  /* background-color: rgb(7, 87, 119); */
  color: #7f8cfd;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  /* background-color: rgb(7, 87, 119); */
  width: 100%;
  margin-top: auto;
  display: flex;
  justify-content: start;
  align-items: center;
}

.menu-bars {
  /* margin-left: 5px; */
  font-size: 20px;
  background-color: rgb(104, 104, 223);
}

.nav-menu {
  background-color: #101924;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  /* left: -100%; */
  transition: 850ms;
  z-index: 10;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.text-nd {
  display: flex;
  justify-content: end;
  align-items: end;
}

/* .bg-dash {
  height: 100%;
  background-image: url('../public/images/DarkBackground_3.jpg') !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
} */

.inv-dis {
  width: 23% !important;
}

.btn-inv {
  width: 100% !important;
}

@media (min-width: 991px) {
  .rein-crd {
    width: 60% !important;
    margin: 0 auto !important;
  }
}
@media (min-width: 768px) {
  .rein-crd {
    width: 100% !important;
    margin-left: 30% !important;
  }
}
@media (max-width: 425px) {
  .rein-crd {
    width: 50% !important;
    margin-left: 30% !important;
  }
}
/* @media (min-width: 375px) {
  .rein-crd {
    width: 50% !important;
    margin-left: 30% !important;
   
  }
} */

.rein-crd {
  /* margin-left: 37% !important; */
}

.rein-crd-1 {
  width: 50% !important;
  margin: auto;
}

.rein-crd-2 {
  width: 100% !important;
  margin-left: 40%;
  height: 55% !important;
}

.rein-crd-3 {
  width: 50% !important;
  margin-left: 33%;
  /* margin-top: 4% !important; */
}
@media (min-width: 800px) {
  .rein-crd-4 {
    margin-top: -55% !important;
    margin-left: 85% !important;
  }
  .rein-crd-3 {
    height: 50% !important;
  }
}
.rein-crd-4 {
  width: 55% !important;
  margin-left: 33%;
}

.rein-crd-5 {
  width: 100% !important;
  margin-left: 30%;
  background-color: transparent !important;
}

.draw-hd {
  margin-left: 30% !important;
}

.draw-hd-0 {
  margin-top: -25% !important;
  margin-left: 40% !important;
}

.draw-hd-1 {
  /* margin-top: 6% !important; */
  margin-left: 29% !important;
}

.draw-hd-2 {
  /* margin-top: -4% !important; */
  margin-left: 33% !important;
}

.font-sze {
  font-size: 0.8em !important;
}

.dash-text {
  color: #364a63 !important;
}

.dash-btn {
  background-color: var(--C_success) !important;
  color: #fff !important;  
  font-size: 14px !important;
}

.basic {
  background-color: #ebeef2 !important;
  color: #a3b1c4 !important;
  font-weight: bold !important;
  font-size: 0.8em !important;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif !important;
  padding-left: 20px !important;
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  border-radius: 5px !important;
}

/* .admin-card {
  width: 44% !important;
} */

.admin-card-marg {
  margin-left: 53px !important;
}

.table-style {
  width: 98% !important;
  margin-bottom: 40% !important;
}

.errorMsg {
  color: var(--bs-danger, red);
}

.cursor-pointer {
  cursor: pointer;
}

.edit-profile:hover {
  background-color: #ebeef2;
  /* padding: 15px; */
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.dash-nav-bg {
  background-color: #101924;
}

.modal-txt {
  color: #8296b0;
  font-size: 1em;
  font-weight: bold;
}

.modal-txt2 {
  color: #344357;
  font-size: 0.9em;
  font-weight: bold;
}

.modal-txt3 {
  color: #8296b0;
  font-size: 0.9em;
}

.form-custom {
  /* width: 100%;
  height: 40px; */
  background-color: black;
  border-radius: 5px;
  border: unset;
  padding-left: 10px;
  padding-right: 10px;
  /* font-size: 0.5em; */
  color: #8296b0;
}

.modal-txt4 {
  color: #344357;
  font-size: 1.1em;
  font-weight: bold;
}

.pass-modal {
  color: var(--C_black_lite);
  font-size: 1.2em;
  font-weight: bold;
}
.pt-5rem {
  padding-top: 5rem;
}
.h-screen {
  height: 100vh;
}
.sidebar-text {
  transition: all 0.7s;
}
.closed-sidebar-text {
  width: 0%;
  display: none;
}
@media (min-width: 500px) {
  .closed-nav-menu {
    width: 100px !important;
  }
}
.closed-nav-menu {
  width: 80px !important;
}
.min-h-screen {
  min-height: 100vh;
}
